'use client';

import Image from 'next/image';
import AppStoreButton from '@/public/download-on-app-store-white.svg';

export const DEFAULT_IOS_APP_LINK = 'https://apps.apple.com/app/id1640766056';
export default function AppleAppStoreDownloadButton({ downloadAppLink }) {
  return (
    <div>
      <a href={downloadAppLink ?? DEFAULT_IOS_APP_LINK}>
        <Image
          src={AppStoreButton}
          alt="Download Afterhour on the Apple app store"
          className="h-full w-auto"
        />
      </a>
    </div>
  );
}
