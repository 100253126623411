import clsx from 'clsx';
import Link from 'next/link';
import { ComponentPropsWithoutRef } from 'react';

const baseStyles = {
  solid:
    'inline-flex justify-center rounded-full py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors',
  outline:
    'inline-flex justify-center rounded-full border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
};

const variantStyles = {
  solid: {
    white:
      'bg-white text-[#662CE2] font-bold hover:bg-white/90 active:bg-white/90 active:text-purple-900/70',
    purple:
      'bg-[#662CE2] text-white font-bold hover:bg-[#662CE2]/90 active:bg-[#662CE2]/90 active:text-white/70',
  },
  outline: {
    white:
      'border-white text-white font-bold hover:border-white/90 active:bg-white/90 active:text-white/80',
    purple:
      'border-[#662CE2] text-[#662CE2] font-bold hover:border-[#662CE2]/90 active:bg-[#662CE2]/90 active:text-[#662CE2]/80',
  },
};

type VariantStyles = 'solid' | 'outline';
type ColorStyles = 'white' | 'purple';

type ButtonProps = ComponentPropsWithoutRef<'a'> &
  ComponentPropsWithoutRef<'button'> & {
    variant?: VariantStyles;
    color?: ColorStyles;
    href?: string;
    className?: string;
  };

export function Button({
  variant = 'solid',
  color = 'white',
  href,
  className,
  ...props
}: ButtonProps) {
  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    className,
  );

  return href ? (
    <Link href={href} className={className} {...props} />
  ) : (
    <button className={className} {...props} />
  );
}
