import clsx from 'clsx';
import { ComponentPropsWithoutRef } from 'react';

const formClasses =
  'block w-full appearance-none rounded-full border border-purple-200 border-4 bg-white py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-gray-900 font-medium placeholder:text-gray-400 focus:border-purple-500 focus:outline-none focus:ring-purple-500 focus:ring-2 text-md lg:text-xl';

interface LabelProps {
  id: string;
  children?: React.ReactNode;
}

function Label({ id, children }: LabelProps) {
  return (
    <label htmlFor={id} className="mb-2 block text-sm font-semibold text-white">
      {children}
    </label>
  );
}

interface TextFieldProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  label?: string;
  type: string;
  className?: string;
  formClassName?: string;
}

export function TextField({
  id,
  label,
  type = 'text',
  className,
  formClassName,
  ...props
}: TextFieldProps) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input
        id={id}
        type={type}
        {...props}
        className={clsx(formClasses, formClassName)}
      />
    </div>
  );
}
