'use client';
import type { NextPage } from 'next';
import Image from 'next/image';
import { Container } from '@/components/Container';
import LogomarkWhite from '@/public/logomark-white.svg';
import HeroImage from '@/public/hero.png';
import WaitlistForm from './WaitlistForm';
import AppleAppStoreDownloadButton from '@/components/AppleAppStoreDownloadButton';
import GooglePlayDownloadButton from '@/components/GooglePlayDownloadButton';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';

const TWITTER_LINK = 'https://twitter.com/afterhour_hq';
const DISCORD_LINK = 'https://discord.gg/zuWKyDXnxD';

const Hero: NextPage = () => {
  return (
    <div>
      <div className="overflow-scroll pt-20 sm:pt-32">
        <Container>
          <div className="text-center align-middle lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20 lg:text-left">
            <div className="relative z-10 mx-auto max-w-2xl lg:col-span-6 lg:max-w-none lg:pt-6 xl:col-span-6">
              <div className="mx-auto w-max lg:mx-0">
                <Image
                  src={LogomarkWhite}
                  alt="AfterHour logo"
                  className="h-12 w-auto"
                />
              </div>
              <h1 className="mt-10 font-anton text-4xl lg:text-5xl">
                It&apos;s like peeking at your friend&apos;s Robinhood app
              </h1>
              <p className="mt-6 px-5 text-lg font-medium lg:px-0 lg:text-xl">
                Follow stock portfolios with real numbers from real people in
                real-time.
              </p>
              <p className="mt-6 px-5 text-lg font-medium lg:px-0 lg:text-xl">
                Join{' '}
                <span className="mx-1 font-anton text-xl">
                  <span className="text-[#FEEF1B]">5000+</span> verified degens
                </span>{' '}
                in the AfterHour community and celebrate your wins (or losses)
                together!
              </p>
              <div className="mt-8 flex h-16 flex-row justify-center space-x-4">
                <AppleAppStoreDownloadButton downloadAppLink={undefined} />
              </div>
              <div>
                <div className="mt-8 flex h-fit flex-col justify-center gap-x-6 lg:justify-start">
                  <div className="mb-4">
                    Coming soon to Google Play! Sign up for the waitlist to get
                    notified when we launch.{' '}
                  </div>
                  <WaitlistForm />
                </div>
              </div>
            </div>
            <div className="relative mt-36 sm:mt-20 lg:col-span-6 lg:row-span-2 lg:mt-0 xl:col-span-6">
              <div className="-ml-12 -mt-24 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:-mt-6 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:mx-0 lg:mt-0 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
                <Image src={HeroImage} alt="Screenshot" priority />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <footer className="bottom-0 z-30 w-full p-4 text-center sm:px-4">
        <div className="mx-auto flex flex-row items-start space-x-4 px-4 text-white">
          <div>
            <a href={TWITTER_LINK}>
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </div>
          <div>
            <a href={DISCORD_LINK}>
              <FontAwesomeIcon icon={faDiscord} size="2x" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Hero;
