'use client';

import { useState, ChangeEvent, FormEvent } from 'react';
import { Button } from '@/components/Button';
import { TextField } from '@/components/Fields';
import { TailSpin } from 'react-loader-spinner';

export default function WaitlistForm() {
  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFetching(true);

    // BAD KEVIN: This is a bad way to do this.
    // We should NOT be pinging a production endpoint on every environment
    const endpoint = 'https://api-legacy.afterhour.com/signups/';
    const payload = JSON.stringify({ email });

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: payload,
    };

    try {
      const res = await fetch(endpoint, options);
      if (res.status === 201) {
        const result = await res.json();
        // Double-check server returns the email we sent
        if (result.email === email) {
          setIsSuccess(true);
        } else {
          setIsError(true);
          console.error(`Unexpected response from server: ${result}`);
        }
      }
    } catch (err) {
      setIsError(true);
      console.error(err);
    }
    setIsFetching(false);
  };

  return (
    <>
      {isError && (
        <div className="mb-4 w-full font-bold text-purple-400">
          Sorry, something went wrong. Please try again
        </div>
      )}
      {isSuccess ? (
        <div className="w-full text-xl font-bold text-yellow-400">
          Thanks for joining the waitlist.
          <br />
          We&apos;ll email <span className="text-purple-400">{email}</span> when
          we launch!
          <br />
          <br />
          Psst... we provide sneak peaks in our{' '}
          <a
            href="https://discord.gg/zuWKyDXnxD"
            target="_blank"
            rel="noreferrer"
            className="text-purple-400"
          >
            discord server
          </a>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full align-baseline lg:flex lg:w-full lg:justify-start"
        >
          <TextField
            id="email"
            type="email"
            aria-label="Email address"
            placeholder="Email address"
            autoComplete="email"
            value={email}
            onChange={handleChange}
            disabled={isFetching}
            required
            className="mx-auto w-60 min-w-0 shrink lg:mx-0"
            formClassName="pl-4 lg:py-4"
          />
          <Button
            type="submit"
            color="purple"
            variant="solid"
            disabled={isFetching}
            className="mt-4 px-8 py-5 text-xl lg:ml-4 lg:mt-0"
          >
            {isFetching ? (
              <TailSpin
                height="28"
                width="28"
                color="#fff"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            ) : (
              <>
                Join Waitlist<span className="-mr-1 ml-3">💎</span>
              </>
            )}
          </Button>
        </form>
      )}
    </>
  );
}
